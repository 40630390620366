@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html,
    body {
        @apply scroll-smooth scroll-m-32;
    }
    body {
        @apply font-satoshi font-normal text-base text-body bg-whiten relative z-1;
    }
}

@layer components {}

@layer utilities {

    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        /* IE and Edge */
        scrollbar-width: none;
        /* Firefox */
    }
}

/* CodeEditor Component */
.code-editor {
    @apply border-none;
    counter-reset: line;
}

.code-editor textarea {
    @apply outline-none !pl-10;
}

.code-editor pre {
    @apply !pl-10;
}

.code-editor .editorLineNumber {
    @apply absolute left-0 text-right w-6 font-light;
}

/**/
a[href^="http"]::after,
a[href^="https://"]::after,
a[href^="enfusion://"]::after {
  content: "";
  width: 11px;
  height: 11px;
  margin-left: 4px;
  background-image: url("./images/external-link.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  display: inline-block;
}
a[href^="enfusion://"]::after {
    background-image: url("./images/enfusion-icon.svg");
}